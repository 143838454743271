import './App.css';
import ScreenRouter from './Screens/Router';

function App() {
  return (
    <>
      <ScreenRouter/>
    </>
  );
}

export default App;
