import React from 'react';
import HeroSection from '../../Assets/Components/HomeComponents/HeroSection';

export default function HomeScreen() {
    return (
        <>
            <HeroSection />
        </>
    );
}
