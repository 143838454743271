import React from "react";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import Grid from '@mui/material/Grid2';

export default function SecondaryBtn({ name, value, onClick }) {
    return (
        <>
            <Grid className="navbar-header2-btn">
                <button className='d-flex align-items-center cursor-pointer'
                    value={value}
                    onClick={onClick}>{name}&nbsp;<ArrowForwardIcon className='plus-icon' /></button>
            </Grid>
        </>
    )
}