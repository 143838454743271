import Grid from '@mui/material/Grid2';
import React from "react";
import BrandLogo from "../../Images/JayMamta-Logos/Brand-logo.png";
import AddIcon from '@mui/icons-material/Add';
import SecondaryBtn from '../HomeComponents/SecondaryButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import MenuBookIcon from '@mui/icons-material/MenuBook';


export default function Navbar() {
    return (
        <>
            <Grid>
                <Grid className="navbar-header d-flex justify-content-between align-items-center">
                    <Grid>
                        <p className='navbar-header-para'>Welcome to Our JayMamta Family !</p>
                    </Grid>
                    <Grid className="hide-mobile">
                        <p className='navbar-header-para d-flex align-items-center'>
                            Follow Us On : &nbsp;&nbsp;&nbsp;
                            <FacebookIcon fontSize='small' className='cursor-pointer' />&nbsp;&nbsp;&nbsp;
                            <InstagramIcon fontSize='small' className='cursor-pointer' />&nbsp;&nbsp;&nbsp;
                            <LinkedInIcon fontSize='small' className='cursor-pointer' />&nbsp;&nbsp;&nbsp;
                            <YouTubeIcon fontSize='small' className='cursor-pointer' />
                        </p>
                    </Grid>
                </Grid>
                <Grid className="navbar-header2">
                    <Grid className="navbar-header-menu">
                        <Grid className="navbar-header-menu-img">
                            <Grid className="d-flex align-items-center gap-3">
                                <img src={BrandLogo} alt="Brand-Logo" className='responsive-img' />
                                <Grid>
                                    <h1>JayMamta</h1>
                                    <p className='brand-tagline'>"Your Partner In Every Field"</p>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid className="navbar-header-menu-list hide-mobile">
                            <ul className='list-type-none cursor-pointer'>
                                <li>Home</li>
                                <li>About</li>
                                <li className='d-flex align-items-center gap-5'>Services<AddIcon className='plus-icon' /></li>
                                <li>Pages</li>
                                <li>Blog</li>
                                <li>Contact</li>
                            </ul>
                        </Grid>
                        <Grid className='hide-mobile'>
                            <SecondaryBtn name="BOOK AN APPOINTMENT" />
                        </Grid>
                        <Grid className='hide-desktop'>
                            <MenuBookIcon />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}