import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from '../../Assets/Components/Navbar';
import HomeScreen from "../Home";

export default function ScreenRouter() {
    return (
        <>
            <BrowserRouter>
            <Navbar />
                <Routes>
                    <Route index element={<HomeScreen />} />
                </Routes>
            </BrowserRouter>
        </>
    );
}