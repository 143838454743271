import Grid from '@mui/material/Grid2';
import React from "react";
import JMLogo from "../../../Images/JayMamta-Logos/JM-Logo.png";
import SecondaryBtn from '../SecondaryButton';




export default function HeroSection() {
    return (
        <>
            <Grid className="hero-bg">
                <Grid>
                    <Grid display="flex" justifyContent="center">
                        <img src={JMLogo} alt="JayMamta Brand" />
                    </Grid>
                    <h2 className='hero-bg-h1'>Transforming Dreams into Reality</h2>
                    <h2 className='hero-bg-h2'>Leading Growth in Every Industry</h2>
                    <Grid display="flex" justifyContent="center" marginTop={5}>
                        <SecondaryBtn name="READ MORE"/>
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}